import React, { useMemo } from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  Create,
  SimpleForm,
  DateTimeInput,
  ReferenceInput,
  Show,
  ListButton,
  TopToolbar,
  AutocompleteInput,
  Edit,
  FunctionField,
  BooleanInput,
  TabbedShowLayout,
  Tab,
  ReferenceManyField,
  Button,
  InjectedFieldProps,
  useRecordContext,
  useNotify,
} from 'react-admin'
import CustomShowButton from '@bonliva-admin/custom-showbutton'
import CustomSaveButton from '@bonliva-admin/custom-savebutton'
import CustomEditbutton from '@bonliva-admin/custom-editbutton'
import { calculateAge } from '../helpers/calculateAge'
import { IBooking, IPatient } from '@bonliva-traits/api/types'
import { defaultTheme } from '@bonliva-ui/core/theme'
import { config } from '@bonliva-core/config'
import { useAuth } from '@bonliva-auth/context'
import axios from 'axios'

const numberFormat = new Intl.NumberFormat('sv-SE', {
  style: 'currency',
  currency: 'SEK',
})

const bookingFilters = [
  <BooleanInput
    key="booking-filter-medical-record"
    source="_noMedicalRecordSaved"
    alwaysOn
    label="Visa ej journalförda"
  />,
  <ReferenceInput
    key="booking-filter-patient"
    source="patientId"
    reference="patients"
  >
    <AutocompleteInput
      optionText="name"
      filterToQuery={(searchText) => ({ _search: `${searchText}` })}
    />
  </ReferenceInput>,
  <ReferenceInput
    key="booking-filter-treater"
    source="treaterId"
    reference="treaters"
  >
    <AutocompleteInput
      optionText="name"
      filterToQuery={(searchText) => ({ _search: `${searchText}` })}
    />
  </ReferenceInput>,
  <ReferenceInput
    key="booking-filter-treatment"
    source="treatmentId"
    reference="treatment-categories"
  >
    <AutocompleteInput
      optionText="category"
      filterToQuery={(searchText) => ({ name: `like:${searchText}` })}
    />
  </ReferenceInput>,
]

export const BookingList = () => (
  <List
    filters={bookingFilters}
    sort={{ field: 'Booking.startDate', order: 'DESC' }}
  >
    <Datagrid
      bulkActionButtons={false}
      rowClick={false}
      rowStyle={() => {
        return { cursor: 'auto' }
      }}
    >
      <ReferenceField
        label="Patient"
        reference="patients"
        source="patientId"
        sortable={false}
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="Personnummer"
        reference="patients"
        source="patientId"
        sortable={false}
        link="show"
      >
        <TextField source="personalNumber" />
      </ReferenceField>
      <DateField
        label="Datum"
        source="startDate"
        sortable
        sortBy="Booking.startDate"
      />
      <ReferenceField
        label="Inriktning"
        reference="treatment-categories"
        source="treatmentId"
        sortable={false}
        link={false}
      >
        <TextField source="category" />
      </ReferenceField>
      <ReferenceField
        label="Psykolog"
        reference="treaters"
        source="treaterId"
        sortable={false}
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField
        source="meetingType"
        label="Meeting Type"
        sortable
        sortBy="Booking.meetingType"
      />
      <TextField
        source="status"
        label="Status"
        sortable
        sortBy="Booking.status"
      />
      <TextField
        source="paymentProduct.status"
        label="Betalstatus"
        sortable
        sortBy="paymentProduct.status"
      />
      <FunctionField
        label="Journalförd anteckning"
        source="medicalRecordSavedAt"
        render={(record: IBooking) => {
          return record?.medicalRecordSavedAt === null ? 'Nej' : 'Ja'
        }}
        sortable={true}
        sortBy="Booking.medicalRecordSavedAt"
      />
      <div
        style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
      >
        <CustomEditbutton />
        <CustomShowButton />
      </div>
    </Datagrid>
  </List>
)

export const BookingCreate = () => (
  <Create
    actions={
      <TopToolbar>
        <ListButton label="Gå tillbaka" icon={<></>} />
      </TopToolbar>
    }
  >
    <SimpleForm toolbar={<CustomSaveButton />}>
      <DateTimeInput label="Startdatum" source="startDate" />
      <DateTimeInput label="Slutdatum" source="endDate" />
      <AutocompleteInput
        label="Status"
        source="status"
        choices={[
          { id: 'reserved', name: 'reserved' },
          { id: 'created', name: 'created' },
          { id: 'paid', name: 'paid' },
          { id: 'completed', name: 'completed' },
          { id: 'cancelled', name: 'cancelled' },
          { id: 'aborted', name: 'aborted' },
          { id: 'failed', name: 'failed' },
        ]}
      />

      <AutocompleteInput
        label="Meeting Type"
        source="meetingType"
        choices={[
          { id: 'digital', name: 'digital' },
          { id: 'physical', name: 'physical' },
        ]}
      />

      <ReferenceInput source="patientId" reference="patients">
        <AutocompleteInput
          label="Patient"
          optionText="name"
          filterToQuery={(searchText) => ({ _search: `${searchText}` })}
        />
      </ReferenceInput>

      <ReferenceInput source="treaterId" reference="treaters">
        <AutocompleteInput
          label="Psykolog"
          optionText="name"
          filterToQuery={(searchText) => ({ _search: `${searchText}` })}
        />
      </ReferenceInput>

      <ReferenceInput source="treatmentId" reference="treatment-categories">
        <AutocompleteInput
          label="Behandlingsområde"
          optionText="category"
          filterToQuery={(searchText) => ({ _search: `${searchText}` })}
        />
      </ReferenceInput>
    </SimpleForm>
  </Create>
)

const DownloadReceiptButton: React.FC<InjectedFieldProps> = (props) => {
  const record = useRecordContext(props)
  const { getAccessToken } = useAuth()
  const notify = useNotify()

  const handleDownload = () => {
    ;(async () => {
      axios({
        url: `${config.API_URL}v1/admin/payments/${record.paymentId}/receipt`,
        method: 'GET',
        responseType: 'blob', // important
        headers: {
          Authorization: `Bearer ${await getAccessToken()}`,
        },
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url

          // Get filename from Content-Disposition header
          const contentDisposition = response.headers['content-disposition']
          let filename = 'receipt.pdf' // default filename
          if (contentDisposition) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
            const matches = filenameRegex.exec(contentDisposition)
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, '')
            }
          }

          link.setAttribute('download', filename)
          document.body.appendChild(link)
          link.click()
          link.parentNode?.removeChild(link)
        })
        .catch((error) => {
          notify('Error downloading file', {
            type: 'error',
          })
        })
    })()
  }

  const shouldShowButton = useMemo(
    () =>
      ['completed_payment', 'pending_refund', 'completed_refund'].includes(
        record.status
      ),
    [record]
  )

  return shouldShowButton ? (
    <Button onClick={handleDownload} label="Download receipt" />
  ) : null
}

export const BookingShow = () => (
  <Show
    actions={
      <TopToolbar>
        <ListButton label="Gå tillbaka" icon={<></>} />
      </TopToolbar>
    }
  >
    <TabbedShowLayout>
      <Tab
        label="Allmänt"
        sx={{
          color: defaultTheme.colors.admin.darkGray,
        }}
      >
        <TextField source="id" />
        <ReferenceField
          label="Patient"
          reference="patients"
          source="patientId"
          sortable={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Ålder"
          reference="patients"
          source="patientId"
          sortable={false}
        >
          <FunctionField
            label="Name"
            render={(record: IPatient) => calculateAge(record.personalNumber)}
          />
        </ReferenceField>
        <ReferenceField
          label="Psykolog"
          reference="treaters"
          source="treaterId"
          sortable={false}
        >
          <TextField label="Psykolog" source="name" />
        </ReferenceField>
        <DateField label="Startdatum" source="startDate" showTime={true} />
        <DateField label="Slutdatum" source="endDate" showTime={true} />
        <ReferenceField
          label="Behandlingsområde"
          reference="treatment-categories"
          source="treatmentId"
          sortable={false}
        >
          <TextField source="category" />
        </ReferenceField>
        <TextField source="status" label="Status" />
        <TextField source="meetingType" label="Meeting Type" />
      </Tab>
      <Tab
        label="Betalning"
        sx={{
          color: defaultTheme.colors.admin.darkGray,
        }}
      >
        <ReferenceManyField
          reference="payment-products"
          target="bookingId"
          label=""
          sort={{ field: 'PaymentProduct.createdAt', order: 'DESC' }}
        >
          <Datagrid
            bulkActionButtons={false}
            rowClick={false}
            rowStyle={() => {
              return { cursor: 'auto' }
            }}
          >
            <TextField source="status" label="Status" />
            <DateField label="Skapad" source="createdAt" showTime={true} />
            <FunctionField
              source="requestedAmount"
              render={(record: any) =>
                numberFormat.format(record.requestedAmount / 100)
              }
              label="Belopp"
            />
            <DateField
              label="Betalad"
              source="payment.datePaid"
              showTime={true}
            />
            <TextField source="payment.paymentMethod" label="Betalningsmetod" />
            <TextField
              source="payment.externalPaymentId"
              label="Extern referens"
            />
            <DownloadReceiptButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>

      <Tab
        label="Avancerad"
        sx={{
          color: defaultTheme.colors.admin.darkGray,
        }}
      >
        <TextField source="id" />
        <TextField source="sessionId" />
        <ReferenceField
          label="Patient"
          reference="patients"
          source="patientId"
          sortable={false}
        />
        <ReferenceField
          label="Treater"
          reference="treaters"
          source="treaterId"
          sortable={false}
        />
        <ReferenceField
          label="Treatment"
          reference="treatments"
          source="treatmentId"
          sortable={false}
        />
        <ReferenceField
          label="Treatment Plan"
          reference="treatmentPlans"
          source="treatmentPlanId"
          sortable={false}
        />
        <TextField source="referralPlanId" />
        <ReferenceField
          label="Referral Plan"
          reference="referralPlans"
          source="referralPlanId"
          sortable={false}
        />
        <TextField source="startDate" />
        <TextField source="endDate" />
        <ReferenceField
          label="Status"
          reference="bookingStatuses"
          source="status"
          sortable={false}
        />
        <ReferenceField
          label="Payment"
          reference="payments"
          source="payment"
          sortable={false}
        />
        <ReferenceField
          label="Feedback"
          reference="bookingFeedbacks"
          source="feedback"
          sortable={false}
        />
        <ReferenceField
          label="Treatment Form"
          reference="treatmentForms"
          source="treatmentForm"
          sortable={false}
        />
        <ReferenceField
          label="Payment Product"
          reference="paymentProducts"
          source="paymentProduct"
          sortable={false}
        />
        <ReferenceField
          label="Type"
          reference="bookingTypes"
          source="type"
          sortable={false}
        />
        <ReferenceField
          label="Meeting Type"
          reference="bookingMeetingTypes"
          source="meetingType"
          sortable={false}
        />
        <TextField source="createdAt" />
        <TextField source="canceledAt" />
        <TextField source="patientJoinedAt" />
        <TextField source="patientLeftAt" />
        <TextField source="treaterJoinedAt" />
        <TextField source="treaterLeftAt" />
        <TextField source="isPatientJoined" />
        <TextField source="createdById" />
        <ReferenceField
          label="Created By"
          reference="users"
          source="createdById"
          sortable={false}
        />
        <TextField source="cancelledById" />
        <ReferenceField
          label="Cancelled By"
          reference="users"
          source="cancelledById"
          sortable={false}
        />
        <TextField source="medicalRecordSavedAt" />
        <ReferenceField
          label="Platform Type"
          reference="bookingPlatformTypes"
          source="platformType"
          sortable={false}
        />
      </Tab>
    </TabbedShowLayout>
  </Show>
)

export const BookingEdit = () => (
  <Edit
    transform={(data) => {
      return {
        ...data,
        canceledAt: data.canceledAt || undefined,
        treatmentPlanId: data.treatmentPlanId || undefined,
        patientJoinedAt: data.patientJoinedAt || undefined,
        patientLeftAt: data.patientLeftAt || undefined,
        treaterJoinedAt: data.treaterJoinedAt || undefined,
        treaterLeftAt: data.treaterLeftAt || undefined,
        referralPlanId: data.referralPlanId || undefined,
        medicalRecordSavedAt: data.medicalRecordSavedAt || undefined,
      }
    }}
  >
    <SimpleForm toolbar={<CustomSaveButton />}>
      <DateTimeInput label="Startdatum" source="startDate" />
      <DateTimeInput label="Slutdatum" source="endDate" />
      <AutocompleteInput
        label="Status"
        source="status"
        choices={[
          { id: 'reserved', name: 'reserved' },
          { id: 'created', name: 'created' },
          { id: 'paid', name: 'paid' },
          { id: 'completed', name: 'completed' },
          { id: 'cancelled', name: 'cancelled' },
          { id: 'aborted', name: 'aborted' },
          { id: 'failed', name: 'failed' },
        ]}
      />

      <AutocompleteInput
        label="Meeting Type"
        source="meetingType"
        choices={[
          { id: 'digital', name: 'digital' },
          { id: 'physical', name: 'physical' },
        ]}
      />

      <ReferenceInput source="patientId" reference="patients">
        <AutocompleteInput
          label="Patient"
          optionText="name"
          filterToQuery={(searchText) => ({ _search: `${searchText}` })}
        />
      </ReferenceInput>

      <ReferenceInput source="treaterId" reference="treaters">
        <AutocompleteInput
          label="Psykolog"
          optionText="name"
          filterToQuery={(searchText) => ({ _search: `${searchText}` })}
        />
      </ReferenceInput>

      <ReferenceInput source="treatmentId" reference="treatment-categories">
        <AutocompleteInput
          label="Behandlingsområde"
          optionText="category"
          filterToQuery={(searchText) => ({ _search: `${searchText}` })}
        />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
)
