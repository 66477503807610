import React, { SVGProps } from 'react'

const icons = {
  CalenderFilled: require('./assets/calender-filled.svg'),
  Calender: require('./assets/calender.svg'),
  ClockFilled: require('./assets/clock-filled.svg'),
  Clock: require('./assets/clock.svg'),
  HeartFilled: require('./assets/heart-filled.svg'),
  Heart: require('./assets/heart.svg'),
  HomeFilled: require('./assets/home-filled.svg'),
  Home: require('./assets/home.svg'),
  InboxFilled: require('./assets/inbox-filled.svg'),
  Inbox: require('./assets/inbox.svg'),
  ProfileFilled: require('./assets/profile-filled.svg'),
  Profile: require('./assets/profile.svg'),
  PatientsFilled: require('./assets/patients-filled.svg'),
  Patients: require('./assets/patients.svg'),
  MediaLibraryFilled: require('./assets/media-library-filled.svg'),
  MediaLibrary: require('./assets/media-library.svg'),
  Bell: require('./assets/bell.svg'),
  Bonliva: require('./assets/bonliva.svg'),
  BonlivaLogo: require('./assets/bonliva-logo.svg'),
  BonlivaSlogan: require('./assets/bonliva-slogan.svg'),
  ChevronDown: require('./assets/chevron-down.svg'),
  ChevronLeft: require('./assets/chevron-left.svg'),
  ChevronRight: require('./assets/chevron-right.svg'),
  ChevronUp: require('./assets/chevron-up.svg'),
  Spinner: require('./assets/spinner.svg'),
  Close: require('./assets/close.svg'),
  Alert: require('./assets/alert.svg'),
  Info: require('./assets/info.svg'),
  Search: require('./assets/search.svg'),
  Trash: require('./assets/trash.svg'),
  BankId: require('./assets/bank-id.svg'),
  CheckFilled: require('./assets/check-filled.svg'),
  Check: require('./assets/check.svg'),
  NoVideo: require('./assets/no-video.svg'),
  Video: require('./assets/video.svg'),
  NoMic: require('./assets/no-mic.svg'),
  Mic: require('./assets/mic.svg'),
  Notepad: require('./assets/notepad.svg'),
  DefaultAvatar: require('./assets/default-avatar.svg'),
  Share: require('./assets/share.svg'),
  TwoAvatars: require('./assets/two-avatars.svg'),
  SecondaryAvatar: require('./assets/secondary-avatar.svg'),
  HeartChart: require('./assets/heart-chart.svg'),
  Files: require('./assets/files.svg'),
  ChatBubble: require('./assets/chat-bubble.svg'),
  Chart: require('./assets/chart.svg'),
  Stars: require('./assets/stars.svg'),
  Logout: require('./assets/log-out.svg'),
  LinkAttach: require('./assets/link-attach.svg'),
  fileAttach: require('./assets/attach-file.svg'),
  Plus: require('./assets/plus.svg'),
  AlertDenied: require('./assets/alert-denied.svg'),
  InfoFilled: require('./assets/info-filled.svg'),
  Draft: require('./assets/draft.svg'),
  Phone: require('./assets/phone.svg'),
  TreatmentPlan: require('./assets/treatment-plan.svg'),
  ShareScreen: require('./assets/share-screen.svg'),
  Person: require('./assets/person.svg'),
  Play: require('./assets/play.svg'),
  Pause: require('./assets/pause.svg'),
  QuestionAnswer: require('./assets/question-answer.svg'),
  QuestionAnswerFilled: require('./assets/question-answer-filled.svg'),
  Location: require('./assets/location.svg'),
  SuccessFulBooking: require('./assets/successful-booking.svg'),
  Settings: require('./assets/settings.svg'),
  Physical: require('./assets/physical.svg'),
  Digital: require('./assets/digital.svg'),
} as const

export const IconType = Object.keys(icons).reduce((acc, key) => {
  const value = key as keyof typeof icons
  acc[value] = value
  return acc
}, {} as Record<keyof typeof icons, keyof typeof icons>)

interface Props extends SVGProps<SVGSVGElement> {
  src: keyof typeof icons
}

export const Icon: React.FC<Props> = (props) => {
  const icon = icons[props.src]

  return React.createElement(icon.ReactComponent, props)
}
